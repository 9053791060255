//教材订购
const _initGoodsregister = {
    chinese: 0,
    chineseworkbook: 0,
    math: 0,
    mathworkbook: 0,
    english: 0,
    englishworkbook: 0,
    rkc: 0,
    rkcworkbook: 0,
    history: 0,
    historyworkbook: 0,
    politics: 0,
    politicsworkbook: 0,
    english2: 0,
    english2workbook: 0,
    math2: 0,
    math2workbook: 0,
    chinese2: 0,
    chinese2workbook: 0,
    law: 0,
    lawworkbook: 0,
    education: 0,
    educationworkbook: 0,
    art: 0,
    artworkbook: 0,
    medicine: 0,
    medicineworkbook: 0,
    client: '',
    consignee: '',
    consigneephone: '',
    address: '',
    remark: '',
}

//库存管理
const _initStockmanage = {
    type: '', //物品类型 (教材，耐用品，易耗品)
    name: '', //物品名称
    unit: '', //物品单位
    initialnum: 0, //初始数量
    currentnum: 0, //当前数量
}

//请假管理
const _initAskleave = {
    starttime: '',//开始时间
    endtime: '',//结束时间
    days: '',//请假天数
    reason: '',//请假事由
    approveflag: '',//审核标识
    approvedesc: '',//审批意见
}

//学历转介绍或优惠奖励标准
const _initReferral = {
    project: '', //项目类型
    grade: '', //奖励档次
    iyear: '', //学年
    recruiter: '', //招生员奖励
    edandfi: '', //教务和财务奖励
}


//会计、教师资格奖励标准
const _initAccountingreward = {
    projecttype: '', //项目类型
    post: '', //岗位
    task: '', //月任务人数
    multiple: '', //倍数
    percentage: '', //百分比
}

//执业药师奖励标准
const _initPharmacist = {
    projecttype: '', //项目类型
    begin: '', //报名人数始
    end: '', //报名人数止
    type: '', //类别（个人跟进、转介绍）
    percentage: '', //奖励百分比
    introductionfee: '', //介绍费
}


//活动奖励
const _initActivity = {
    title: '', //标题
    fileurl: '', //文件路径
}


//学历招生保底计划
const _initGuarantee = {
    begin: '', //计划档次始
    end: '', //计划档次止
    recruiter: '', //招生员招生人数
    edandfi: '', //教务和财务招生人数
    person: '', //负责人招生人数
}

//院校收入管理
const _initCollegesincome = {
     levelval:'', //层次
     projecttype:'', //项目类别
     studytypeval:'', //学习形式
     grade:'', //年级
     receivablecollegefee:'', //应收院校管理费
     repayments:'', //应收返还款
}

//退费表单
const _refundForm = {
    id: [], //学生id
    qianzainame: [], //潜在姓名
    reasonsreason: "",//原因
    feebackmoney: 0,//金额
}

//院校收费标准
const _initcollegefeestandard = {
    enrollcount:0, //计划招生人数
     tuitionfee1:0, //第一年学费
     tuitionfee2:0, //第二年学费
     tuitionfee3:0, //第三年学费
     tuitionfee4:0, //第四年学费
     tuitionfee5:0, //第五年学费
     textbookfee1:0, //第一年教材费
     textbookfee2:0, //第二年教材费
     textbookfee3:0, //第三年教材费
     textbookfee4:0, //第四年教材费
     textbookfee5:0, //第五年教材费
     management1:0, //总部管理费1
     management2:0, //总部管理费2
     management3:0, //总部管理费3
     management4:0, //总部管理费4
     management5:0, //总部管理费5
     salesman1:0, //招生员工提成1
     salesman2:0, //招生员工提成2
     salesman3:0, //招生员工提成3
     salesman4:0, //招生员工提成4
     salesman5:0, //招生员工提成5
     educational1:0, //教务提成1
     educational2:0, //教务提成2
     educational3:0, //教务提成3
     educational4:0, //教务提成4
     educational5:0, //教务提成5
     finance1:0, //财务1
     finance2:0, //财务2
     finance3:0, //财务3
     finance4:0, //财务4
     finance5:0, //财务5
     partsalesman1:0, //校外招生员提成1
     partsalesman2:0, //校外招生员提成2
     partsalesman3:0, //校外招生员提成3
     partsalesman4:0, //校外招生员提成4
     partsalesman5:0, //校外招生员提成5
}

//个人计划
const _inituserplan = {
    //cktarget:0,
    //zktarget:0,
    gkgk:0,
    fulltimezsb:0,
    title:0,
    hongkong:0,
    fulltimezz:0,
    skill:0,
    adultzz:0,
    files:0

}

//年度计划
const _initschoolplan = {
  //cktarget:0, //成考目标
  //zktarget:0, //自考目标
    gkgk:0,
    fulltimezsb:0,
    title:0,
    hongkong:0,
    fulltimezz:0,
    skill:0,
    adultzz:0,
    files:0

}

//互动美语招生计划
const _initenglishplan = {
  january:0, //1月
  february:0, //2月
  march:0, //3月
  april:0, //4月
  may:0, //5月
  june:0, //6月
  july:0, //7月
  august:0, //8月
  september:0, //9月
  october:0, //10月
  november:0, //11月
  december:0, //12月
  bdtarget:0, //保底目标
  jhtarget:0, //计划目标
  cctarget:0, //冲刺目标
}
export {
    _initenglishplan,
    _initschoolplan,
    _inituserplan,
    _initcollegefeestandard,
    _initGoodsregister,
    _initStockmanage,
    _initAskleave,
    _initReferral,
    _initAccountingreward,
    _initPharmacist,
    _initActivity,
    _initGuarantee,
    _initCollegesincome,
    _refundForm
}
